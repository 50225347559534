<template>
    <div>
        <el-row style="height:270px">
            <el-col :span="24">
                <img src="	https://z2020-1302616346.cos.ap-hongkong.myqcloud.com/intro2024/cardgame00.jpg" class="bg_image" style="height:270px;width:100%;position: relative;" alt="">
                <div style="color:white;position: absolute; top: 10%; left: 50%; transform: translate(-50%, -50%); z-index: 1; text-align: center;font-size: 30px;font-weight: bold;">
                    欧陆风云:安那其1900
                </div>
            </el-col>
        </el-row>
        <br>        
        

    </div>
  </template>
  
  <script>
  
  export default {
    name: 'p62View',
    components: {
    }
  }
  </script>
  
  <style scoped>  

  .custom-empty-description h2 {  
	font-size: 20px; /* 或者你想要的任何大小 */  
  }  
	
  .custom-empty-description h2:lang(en) {  
	font-size: 18px; /* 英文标题字体大小可以不同 */  
  }

</style>
  

  
