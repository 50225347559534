<template>
    <div>
        <p> 这里有个我的公告，我的介绍图片，其他用户对我的印象。</p>
        <el-row class="firstrow">
        <ShowlistRow  :showprops='$options.name'></ShowlistRow>
      </el-row>

       
        

    </div>
</template>
<script>
import { ref } from 'vue';
import ShowlistRow from '../components/CommonComp/ShowlistRow.vue';

export default {
    name: 'SubPage42',
    components: {
        ShowlistRow

    },
    setup() {
   

        const value2 = ref('')



        return {
            value2,

        };



    },








}

</script>


<style scoped>
.my-date-picker .el-date-picker__input {  
  width: 200px;  
}  
</style>