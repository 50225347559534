<template>
  <div class="mobile-page">
    <div class="top-bar">
      
      <div style="font-size: 7vw;"><b>Aworld.wiki</b></div>
      <div class="exitbutton">
        <a @touchstart="mobsetting" class="mainlinkstyle">设置</a>
        <span>｜</span>
        <a @touchstart="handleLogout" class="mainlinkstyle">退出</a>
      </div>
    </div>
    <div class="grid-container">
      <div @touchstart="navigateTo('/search_mobpage')" class="grid-item2">搜</div>
      <div @touchstart="navigateTo('/followed_mobpage')" class="grid-item1"><div class="two-line">关注<br>收藏</div></div>
      <div @touchstart="navigateToChat" class="grid-item2">聊</div>
      <div @touchstart="navigateTo('/friends_mobpage')" class="grid-item1"><div class="two-line">朋友<br>组织</div></div>
      <div @touchstart="navigateToTodayNews" class="grid-item1"><div class="two-line">今日<br>新闻</div></div>
      <div @touchstart="navigateToRS" class="grid-item1"><div class="two-line">推荐<br>阅读</div></div>
      <div @touchstart="navigateTo('/debate_mobpage')" class="grid-item2">辩</div>
      <div @touchstart="navigateTo('/shared_mobpage')" class="grid-item1"><div class="two-line">共享<br>资料</div></div>
      <div @touchstart="navigateToCreate" class="grid-item2" style="font-weight: bolder;">En</div>
      <div @touchstart="navigateToCommune" class="grid-item2 wide">
        <div class="two-line">西撒事务</div>
      </div>
      <div @touchstart="navigateToMine" class="grid-item1"><div class="two-line">个人<br>事务</div></div>
    </div>
    <br>
    <CommandLine :text="commandText" style="color: #333;" />
    <el-dialog
      v-model="drawerVisible"
      title="请先登录"
      width="100%"
      :show-close="false"
      :close-on-click-modal="false"
      :before-close="handleClose"
      class="custom-dialog"  

    >
      <el-form ref="loginform" v-model="loginForm">
        <br>
        <input 
          autocomplete="off" 
          type="text" 
          placeholder="你的用户名,your username" 
          v-model="loginForm.username"
          class="input_dengluye">
        <br><br><br>
        <input 
          type="password" 
          placeholder="你的密码,your password" 
          v-model="loginForm.password"
          class="input_dengluye">
      </el-form>

      <template #footer>
        <el-divider>
            <a class="my-icon" style="font-size: 25px;"  @touchstart="handleLogin">确定登陆</a>
        </el-divider>
        <br>
        <br>
        <h2>
          <a class="my-icon" style="color: cornflowerblue;">第一次来？点击注册</a>
        </h2>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';
import CommandLine from '../mobilecomponents/CommandLine.vue';  // 导入新组件

const router = useRouter();
const drawerVisible = ref(false);
const username = ref(localStorage.getItem('username') || '');
const commandText = ref(`hello,${username.value}`);
const loginForm = reactive({
  username: '',
  password: ''
});
const error0 = ref('');
const message0 = ref('');

function navigateTo(path) {  
  router.push(path).catch(err => {  
    console.error('Navigation failed:', err);  
  });  
};

const handleLogout = () => {
  localStorage.removeItem('username');
  window.location.reload();
};

const handleLogin = async () => {
  if (!loginForm.username || !loginForm.password) {  
    error0.value = '输入不为空';  
    message0.value = '';  
    return; 
  }  

  try {
    const response = await axios.post('https://www.aworld.wiki/api/iteminfo/login/', {
      username: loginForm.username,
      password0: loginForm.password,
    });
    message0.value = response.data.message;
    error0.value = '';
    localStorage.setItem('username', loginForm.username); 
    drawerVisible.value = false;
    window.location.href = '/';
  } catch (e) {
    error0.value = e.response.data.error;
    message0.value = '';
  }
};

onMounted(() => {  
  if (!username.value) {
    drawerVisible.value = true;
  }
});
</script>

<style scoped>
.mobile-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  background-color: #f0f0f0;
  color: #333;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  font-family: 'Courier New', Courier, monospace;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 2px solid #333;
  margin-bottom: 20px;
}

.exitbutton {
  font-size: 6vw;
  cursor: pointer;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.grid-item1, .grid-item2 {
  background-color: #fff;
  border: 2px solid #333;
  padding: 20px;
  text-align: center;
  aspect-ratio: 1 / 1;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 3px 3px 0 #333;
  transition: all 0.15s ease;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.wide {
  grid-column: span 2;
  aspect-ratio: 2.5 / 1;
  font-size: 30px;
}

.grid-item1::before, .grid-item2::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
    linear-gradient(45deg, transparent 49%, #ddd 49%, #ddd 51%, transparent 51%),
    linear-gradient(-45deg, transparent 49%, #ddd 49%, #ddd 51%, transparent 51%);
  background-size: 6px 6px;
  opacity: 0.1;
  transition: opacity 0.3s ease;
}

.grid-item1:hover, .grid-item2:hover {
  transform: translate(1px, 1px);
  box-shadow: 2px 2px 0 #333;
}

.grid-item1:active, .grid-item2:active {
  transform: translate(3px, 3px);
  box-shadow: 0px 0px 0 #333;
}

.grid-item1:hover::before, .grid-item2:hover::before {
  opacity: 0.2;
}

.grid-item1:active::before, .grid-item2:active::before {
  opacity: 0.3;
}

.grid-item1 {
  font-size: 20px;
}

.grid-item2 {
  font-size: 30px;
}

.two-line {
  display: inline-block;
  text-align: center;
  line-height: 1.2;
}

@keyframes scroll {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

@media (max-width: 768px) {
  .grid-container {
    grid-gap: 5px;
  }
  
  .grid-item1, .grid-item2 {
    padding: 15px;
  }
  
  .grid-item1 {
    font-size: 25px;
  }
  
  .grid-item2 {
    font-size: 40px;
  }
}


/* 使用深度选择器来覆盖 Element UI 的内部样式 */  
/* .custom-dialog /deep/ .el-dialog__title {  
  font-size: 28px;  
}   */
/* 注意：在一些构建工具或 Vue 版本中，你可能需要使用 ::v-deep 而不是 /deep/ */  
::v-deep .el-dialog__title {
  font-size: 28px;
}


.mainlinkstyle{
  color: grey;
  font-weight: bold;
  font-size: 6vw;
}

</style>