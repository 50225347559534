<template>
  <div>
    <el-empty image="https://z2024-1302616346.cos.ap-nanjing.myqcloud.com/underconstruction.jpg"
      description="working overtime">
      <h2>该模块正在建设中，敬请期待!</h2>
      <h2>This section is under construction!</h2>
    </el-empty>
  </div>
</template>
<script>

export default {
  name: 'EmptyImage',
  setup() {
  },
};
</script>