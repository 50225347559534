<template>
    <div>
        <el-row>
            <el-col :span="24">
                <img src="	https://z2020-1302616346.cos.ap-hongkong.myqcloud.com/intro2024/intro11b.jpg" class="bg_image" style="height:270px;width:100%;position: relative;" alt="">
            </el-col>
        </el-row>
        <el-row style="text-align:left;">
                <p>
                    <span>西撒哈拉位于非洲西北部，北临摩洛哥，是如今非洲大陆上唯一没有独立的地区。 1976年西撒哈拉人民解放阵线组织(简称西撒人阵)就宣布成立“撒拉威阿拉伯民主共和国”，但这个共和国既不是联合国成员国，也没有得到包括中、美等世界上大多数国家的承认，而北边的摩洛哥更是声称享有西撒哈拉的主权，并强占了西撒哈拉绝大多数土地。“11人房间”是西撒人阵政治结构中最小的单元。这些机构通常负责本地区的食物、水和教育分配，并且与更高一级的机构(包括几个营地)合作，建立分配链。 西撒人阵由秘书长领导，秘书长由每四年定期召开一次的人民代表大会选举产生。所有难民营居民都有人民代表大会的投票权。西撒人阵没有正式成员，任何参与该组织工作或在难民营生活的人都被视为该组织的成员。 西撒人阵控制的沙墙以东1/4地区，地处撒哈拉沙漠腹地，自然条件极其恶劣。阿拉伯撒哈拉民主共和国把西撒哈拉分为5大行政区，但实际上仅控制与摩洛哥、毛里塔尼亚、阿尔及利亚交界的狭窄地带。</span>
                    <span>Western Sahara, located in the northwest of Africa and bordered by Morocco to the north, remains the only region in the African continent that has not achieved independence. In 1976, the Popular Front for the Liberation of Saguia el-Hamra and Río de Oro (commonly known as Polisario Front) declared the establishment of the Sahrawi Arab Democratic Republic. However, this republic is neither a member of the United Nations nor recognized by most of the world’s countries, including China and the USA. Morocco, on its northern border, claims sovereignty over Western Sahara and occupies most of its territory.

The "11-Person Room" represents the smallest unit within the political structure of the Polisario Front. These institutions typically assume responsibility for the distribution of food, water, and education in their local areas and collaborate with higher-level institutions (including several camps) to establish a distribution chain.

The Polisario Front is led by a Secretary-General, who is elected every four years by the Congress of People's Delegates. All residents of the refugee camps have the right to vote in the Congress of People's Delegates. The Polisario Front does not have formal members; anyone who works for the organization or lives in the refugee camps is considered part of the organization.

The area east of the berm controlled by the Polisario Front constitutes about a quarter of Western Sahara, situated deep within the Sahara Desert, with extremely harsh natural conditions. The Sahrawi Arab Democratic Republic divides Western Sahara into five administrative regions, but in reality, it only controls a narrow strip of land at the borders with Morocco, Mauritania, and Algeria.</span>
                    <a class="a_grey">Translate to English</a> 
                </p>
                
        </el-row>
        <br>
        <el-row>
            <el-col :span="10">
                <el-image
                    style="border:1px solid grey"
                    src="	https://z2020-1302616346.cos.ap-hongkong.myqcloud.com/intro2024/intro11sanlian.jpg"
                    :fit="fill">
                </el-image>
            </el-col>
            <el-col :span="14">
                <div class="huanhang" style="text-align:left;">
                <b class="font20px">欢迎来到西撒哈拉自由地区<span style="color: orange;">(幻想)</span></b>
                <br>
                来自世界各地的安那其主义者加入西撒人阵，共同反抗摩洛哥政府的侵略，并建立了西撒自由地区。地区内人民一致达成以下契约：
                <br>
                加入自愿，退出自由。地区内全部已有自然资源归总公社全体成员所有，不承认任何知识产权，保护个人著作署名权。涉及全民生存与发展的生活必需产品实行按需分配，与之相关生产资料实行公有制，个人在闲暇之余的创作物归个人所有。全体人民仅对契约内的公共事业行为负责，除此之外一切个人行为后果由本人负责。
                人民自主建设“必需的生活条件”，让自己和后代能在其中生活与发展，不被资本胁迫以换取生存权，这就是最根本的自由！
                </div>
            </el-col>
        </el-row>
        <br>
        <el-row>
            <el-col :span="14">
                <div class="huanhang" style="text-align:right;">
                <b class="font20px">组织架构</b>		
                <br>	
                人民的日常生活通过各类公社进行自治。每个人自由选择组成二级公社，进而组成一级公社，不参加任何公社的独居者组成独居者协会，总公社负责协调所有人民的利益。涉及全民和跨公社的细分行业由各类协会进行管理，涉及全体人民利益的公共协会与涉及独特偏好的个性协会，协会日常管理人员由选举组成，定期轮换。各类公社与协会的所有信息应公示和公开，反对任何秘密结社活动。作为反制措施，总公社应保护那些揭露秘密结社行为的个人。
                <br>
                </div>
            </el-col>
            <el-col :span="10">
                <el-image
                    style="border:1px solid grey"
                    src="	https://z2020-1302616346.cos.ap-hongkong.myqcloud.com/intro2024/intro11jiegou.jpg"
                    :fit="fill">
                </el-image>
            </el-col>
        </el-row>

        <br>
        <el-row>
            <el-col :span="10">
                <el-image
                    style="border:1px solid grey"
                    src="	https://z2020-1302616346.cos.ap-hongkong.myqcloud.com/intro2024/intro11community.jpg"
                    :fit="fill">
                </el-image>
            </el-col>
            <el-col :span="14">
                <div class="huanhang" style="text-align:left;">
                <b class="font20px">建设一种自由自主的生产体系</b>
                <br>
                西撒自由地区旨在建设一个自由自主，人人参与的生产体系，保障人民最基本的生活需求，创造一种公用共享，极简透明的产品设计标准，摆脱资本，消费主义，官僚体制的剥削。
                共享劳动经验，共享信息与软件，资金与设备的互相支援，基础科技的共同研发，实现一种“自由的联合”。避免重复试错，摊平基建成本。工人之间的自由流动，以最通俗简单的形式简化劳动与管理流程，让每个员工同时具备执行者，管理者，创造者三种角色的能力，真正做到“全面发展的人”。
                </div>
            </el-col>
        </el-row>
    </div>
  </template>
  
  <script>
  
  export default {
    name: 'p11View',
    components: {
    }
  }

  
  </script>
  
<style scoped>



</style>