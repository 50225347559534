
<template>
  <div>
    <el-row class="firstrow">
      <ShowlistRow  :showprops='$options.name'  @request-data="requestDataFromChild"></ShowlistRow>
    </el-row>


<div v-for="item in tableData" :key="item.pk">
  {{ item.pk }}
</div>


    <!-- <el-table :show-header="false" :data="tableData" style="width: 100%;font-size: 18px;">
      <el-table-column label="" width=auto>
        <template #default="scope">

          <ItemSpan itemKey="通用元素0" :itemObject="scope.row" > </ItemSpan>

          <div v-show="scope.row.cztype === '关联'">
            <el-row>
              <span>
                <span><ItemSpan itemKey="通用元素0" :itemObject="scope.row" ></ItemSpan></span>
                <span>关联了</span>
                <span><ItemSpan itemKey="通用元素1" :itemObject="scope.row" ></ItemSpan></span>
                <span>来自<ItemSpan itemKey="操作用户" :itemObject="scope.row" ></ItemSpan>，附言<b>:</b></span>
                <span><ItemSpan itemKey="操作附言" :itemObject="scope.row" ></ItemSpan></span>
              </span>
            </el-row>
            <ReplySpan :itemObject1="scope.row"></ReplySpan>
          </div>

          <div v-show="scope.row.cztype === '评论'">
            <el-row>
              <span>
                <span><ItemSpan itemKey="通用元素0" :itemObject="scope.row" ></ItemSpan></span>
                <span>被</span>
                <span>用户<ItemSpan itemKey="操作用户" :itemObject="scope.row" ></ItemSpan>评论说<b>:</b></span>
                <span><ItemSpan itemKey="操作附言" :itemObject="scope.row" ></ItemSpan></span>
              </span>
            </el-row>
            <ReplySpan :itemObject1="scope.row"></ReplySpan>
          </div>
    
          <div v-show="scope.row.cztype === '补充标签'">
            <el-row>
              <span>
                <span><ItemSpan itemKey="通用元素0" :itemObject="scope.row" ></ItemSpan></span>
                <span>被补充到标签</span>
                <span><ItemSpan itemKey="通用元素1" :itemObject="scope.row" ></ItemSpan></span>
                <span>，来自用户<ItemSpan itemKey="操作用户" :itemObject="scope.row" ></ItemSpan>，附言<b>:</b></span>
                <span><ItemSpan itemKey="操作附言" :itemObject="scope.row" ></ItemSpan></span>
              </span>
            </el-row>
            <ReplySpan :itemObject1="scope.row"></ReplySpan>
          </div>


        </template>
      </el-table-column>
    </el-table> -->


  </div>
  

</template>
<script>  
import { ref} from 'vue';  

import axios from 'axios'; 
import ItemSpan from '../components/tiny_cmpt/ItemSpan.vue';
import ShowlistRow from '../components/CommonComp/ShowlistRow.vue';
import { ElLoading } from 'element-plus'


export default {  
  name: 'SubPage02', 
  components: {  
  
      ShowlistRow,
      ItemSpan,
    },  
  setup() {
      const tableData = ref([]);
      const fetchData = async () => {  
      let loadingInstance = ElLoading.service({
        lock: true,
        text: '加载中...',
        background: 'rgba(0, 0, 0, 0.7)'
      }); // 启动加载动画
      try {  
        const response = await axios.post('https://www.zhishiren.info/api/xunhuan2024ceshi/',{userid:90000001});  
        tableData.value = await JSON.parse(response.data);
        // 立即将 freshtabledata.value 设置为 false，表示开始加载新数据  
 
      } catch (error) {  
        console.error(error);  
      }  finally {  
        loadingInstance.close(); // 关闭加载动画
      }  
    };  
    const requestDataFromChild = () => {
      fetchData();  
    };


        return {
            tableData,
            fetchData,
            requestDataFromChild

        };




    },
}

</script> 


<style scoped>
::v-deep .el-table__cell {
    padding-bottom: 0 !important;
    /* 或需要调整padding来去除间距 */
}


</style>


