<template>
    <div>
        <el-row style="height:270px">
            <el-col :span="24">
                <img src="	https://z2020-1302616346.cos.ap-hongkong.myqcloud.com/intro2024/anrun.jpg" class="bg_image" style="height:270px;width:100%;position: relative;" alt="">
                <div style="color:white;position: absolute; top: 10%; left: 50%; transform: translate(-50%, -50%); z-index: 1; text-align: center;font-size: 30px;font-weight: bold;">
                    安润行动
                </div>
            </el-col>
        </el-row>
        <br>        
    </div>
  </template>
  
  <script>
  
  export default {
    name: 'p63View',
    components: {
    }
  }
  </script>
  