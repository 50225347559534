
<!-- 这是放在各种遍历列表中的一个组件，用来展示列表的一行内容，包括展开和收起的功能，以及一些操作按钮，比如刷新、查找、排序等。 -->

<template>
    <span style="font-size:18px;">
      <span style="vertical-align: middle;">
        <span>你已经增添了0条记录</span>
        <!-- <span>共有10条正方论述和1条反方论述，你已发表了0条论述</span>
        <span>共有3条关联的知识点</span>
        <span>共有12条用户操作记录</span> -->
      </span>
  
      <el-button key="info" type="info" text class="custom-button" v-if="showList" @click="toggleshowList">
        <el-icon class="my-icon"><Expand /></el-icon>展开
      </el-button>
  
      <span v-if="!showList">
        <el-tooltip content="<h2>刷新refresh</h2>" raw-content>
          <el-button key="info" type="info" text class="custom-button" @click="reflash">
            <el-icon class="my-icon">
              <Refresh />
            </el-icon>
          </el-button>
        </el-tooltip>
        <el-divider direction="vertical" />
        <el-tooltip content="<h2>查找|筛选|排序</h2><h2>search&sort</h2>" raw-content>
          <el-button key="info" type="info" text class="custom-button" @click="show_dialog('查找与排序的参数')">
              <el-icon class="my-icon">
                <Tools />
              </el-icon>
          </el-button>
        </el-tooltip>
      </span>
  
      <el-dialog v-model="dialog0Visible" title="筛查设置:" width="25%"  :append-to-body="true">
        <el-form :model="form" :rules="rules" ref="form">
  
          <el-form-item label="查找内容：" prop="name">
            <el-input placeholder="请输入你要查找的关键词" style="width: 300px;"></el-input>
          </el-form-item>
  
          <el-form-item label="排序方式：" prop="age">
            <el-input style="width: 300px;"></el-input>
          </el-form-item>
  
          <el-form-item label="选择态度：" prop="age">
            <el-input style="width: 300px;"></el-input>
          </el-form-item>
  
          <el-form-item label="选择标签：" prop="age">
            <el-input style="width: 300px;"></el-input>
          </el-form-item>
  
          <el-form-item label="公开范围：" prop="age">
            <el-input style="width: 300px;" placeholder=""></el-input>
          </el-form-item>
  
          <el-form-item label="类型属性：" prop="age">
            <el-input style="width: 300px;"></el-input>
          </el-form-item>
  
          <el-form-item label="特别关注：" prop="age">
            <el-select placeholder="请选择是否特别关注该用户" style="width: 300px;">
              <el-option label="是" value="0" />
              <el-option label="否" value="1" />
            </el-select>
          </el-form-item>
  
        </el-form>
  
<!-- 这个组件作废 -->
        <!-- <EditableDiv @input="handleContentChange" /> -->

        <!-- <span style="font-size: 18;">
          <el-button key="info" type="info" text>
            <b>所选加粗</b>
          </el-button>
          <el-button key="info" type="info" text style="float:right;">
            <b>取消加粗</b>
          </el-button> -->
          <!-- <el-button key="info" type="info" text style="float:right;" >
                <mark>所选标黄</mark>
              </el-button> -->
        <!-- </span> -->
        <br>
        <br>
        <el-divider>
          <span style="font-size: 18px;">
            <a class="my-icon">提交</a>
            <el-divider direction="vertical" />
            <a class="my-icon" @click="dialog0Visible = false">取消</a>
          </span>
        </el-divider>
  
  
      </el-dialog>
    </span>
  </template>
<script>
import { ref } from 'vue';  
// import EditableDiv from '../tiny_cmpt/EditableDiv.vue';

export default {  
  props: {
    showprops: {
      type: String,  
      default: '111'  
    }  
  },
  setup(props, { emit }) {  
    // 定义 emits  
// 定义处理内容变化的函数
    const handleContentChange = (content) => {
      console.log('Content changed:', content);
      // 处理内容变化
    };

    // 声明响应式变量  
    const showList = ref(true);  
    const dialog0Visible = ref(false);  

  
    // 方法定义  
    const toggleshowList = () => {  
      showList.value = !showList.value;  
      emit('request-data'); 
    };  
  
    const reflash = () => {  
      // 实现刷新逻辑  
    };  
  
    const show_dialog = (param) => {  
      dialog0Visible.value = true;  

    };  
  
    return {  
      showList,  
      dialog0Visible,  
      toggleshowList,  
      reflash,  
      show_dialog,
      handleContentChange
    };  
    
  } 
};  
</script>

  
  <style scoped>
  /* 以下代码时用来放大对话框的各个元素的字体大小 */
  .el-form-item /deep/ .el-form-item__label {
    font-size: 18px;
    /* 或其他你想要的字体大小 */
  }
  
  /* 这是气泡对话框的字体设置 */
  /* .el-popper.is-customized {
    padding: 6px 12px;
    font-size: 18px; 
    background: linear-gradient(90deg, rgb(159, 229, 151), rgb(204, 229, 129));
  }
  
  .el-popper.is-customized .el-popper__arrow::before {
    background: linear-gradient(45deg, #b2e68d, #bce689);
    right: 0;
  } */
  
  .input_comment {
    background-color: rgba(0, 0, 0, 0);
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-color: grey;
    height: 30px;
    width: 100%;
    color: black;
    font-size: 20px;
    outline: none;
  }
  
  
  .pinglunlan {
    width: 100%;
    border: none;
    border-radius: 0;
    border-bottom: #8D8D8D 1px solid;
    box-shadow: 0;
    outline: none;
    text-decoration: none;
    font-size: 20px;
  }
  </style>